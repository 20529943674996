<!--
  面包屑
-->
<template>
  <div class="breadcrumb">
    <!-- <img src="../../../assets/img/location.png" alt=""> -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-for="i in crumbList">
        <el-breadcrumb-item :key="i.title" v-if="i.to" :to="{ path:i.to,query:i.data}">
          <router-link :to="i.to">{{i.title}}</router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item :key="i.title" v-else>{{ i.title }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    item: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data () {
    return {
      crumbList: this.item,
    }
  },
  watch: {
    $route (route) {
      this.handleRoute()
    },
    item (val) {
      this.crumbList = val
    },
  },
  created () {
    console.log(this.$route)
    this.handleRoute()
  },
  methods: {
    //切换路由
    handleRoute () {
      this.crumbList = [this.$route.meta]
    },
    //数组循环问题
    menuEach (list, arr) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index]
        if (element.url == this.$route.path) {
          arr.push({ title: element.name })
          break
        } else {
          if (element.children.length > 0) {
            this.menuEach(element.children, arr)
          }
        }
      }
      list.find(element => element.url == this.$route.path)
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .el-breadcrumb__inner {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #666 !important;
}
::v-deep .el-breadcrumb__inner.is-link a {
  color: #0076f6 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.breadcrumb {
  display: flex;
  align-items: center;
  // margin-bottom: 20px;
  img {
    width: 12px;
    height: 16px;
    margin-right: 10px;
  }
}
</style>
